import { Component, OnInit } from '@angular/core';

import { PageHeadingService } from '../../services/page-heading.service';
@Component({
  selector: 'app-common-dashboard',
  templateUrl: './common-dashboard.component.html',
  styleUrls: ['./common-dashboard.component.css']
})
export class CommonDashboardComponent implements OnInit {

  constructor(
    private pageHeadingService: PageHeadingService
  ) { }

  ngOnInit(){
    this.pageHeadingService.setPageHeading("Dash Board");
  }

}
