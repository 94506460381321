import { Component, OnInit } from '@angular/core';
import { PageHeadingService } from '../../services/page-heading.service';
import { ProfileService } from '../../profile/profile.service';

@Component({
  selector: 'app-teacher-dashboard',
  templateUrl: './teacher-dashboard.component.html',
  styleUrls: ['./teacher-dashboard.component.css']
})
export class TeacherDashboardComponent implements OnInit {

  constructor(
    private pageHeadingService: PageHeadingService,
    private profileService: ProfileService
  ) { }

  ngOnInit() {
    this.pageHeadingService.setPageHeading("Live Session DashBoard");
  }

}
