import { Component, OnInit } from '@angular/core';

import { PageHeadingService } from '../../services/page-heading.service';
@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

  constructor(
    private pageHeadingService: PageHeadingService
  ) { }

  ngOnInit() {
    this.pageHeadingService.setPageHeading("Page Not Found");
  }

}
