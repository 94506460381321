//Author :Jibin Sebastian
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { HttpConfigService } from '../services/http-config.service';

import { ProfileService } from '../profile/profile.service';
const httpOptions = {
  headers: new HttpHeaders({
  })
};


@Injectable()
export class EventService{
   constructor(
     private http: HttpClient,
     private httpConfigService: HttpConfigService,
     private profileService: ProfileService
   ) { }

   baseURL = this.httpConfigService.baseURL;
   baseERPURL = this.httpConfigService.baseERPURL;
   //allEventListAPI = this.baseURL + 'event/all/';
   myEventListAPI = this.baseURL + 'event/my/';
   newEventAPI = this.baseURL + 'gcalendar/new/';

   inviteAllPHPAPI=this.baseERPURL+'Live Class/invite_all.php';
  // retOneEventAPI = this.baseURL + 'event/getone/';
   //addNewEventAPI = this.baseURL + 'event/new'
   //delOneEventAPI = this.baseURL + 'event/delone/'

   //configUrl = this.baseURL+'auth/5e312ab2da935';
   //postUrl = this.baseURL+"file";

  // getAllEvents() {
  //   return this.http.get(this.allEventListAPI);
  // }
  getMyEvents(curLoggedUserId){
    //console.log(this.profileService);
    return this.http.get(this.myEventListAPI+curLoggedUserId);

  }
  // getOneEvent(selEvent){
  //   return this.http.get(this.retOneEventAPI+selEvent);
  // }
  addNewEvent(eventData){
    console.log(eventData);
    return this.http.post(this.newEventAPI+this.profileService.curLoggedUserId,eventData,httpOptions);
    //console.log(selectedFile+" is Selected");
  }

  inviteAll(eventId){
    return this.http.get(this.inviteAllPHPAPI+'?event='+eventId);
  }
  // deleteEvent(selFileKey){
  //   return this.http.delete(this.delOneEventAPI+selFileKey+'/'+this.profileService.curLoggedUserId);
  // }


}
