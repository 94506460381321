import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-common-footer',
  templateUrl: './common-footer.component.html',
  styleUrls: ['./common-footer.component.css']
})
export class CommonFooterComponent implements OnInit {

  footerCompName="Livonia Technologies Pvt Ltd";
  constructor() { }

  ngOnInit() {
  }

}
