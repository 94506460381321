import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-common-titlebar',
  templateUrl: './common-titlebar.component.html',
  styleUrls: ['./common-titlebar.component.css']
})
export class CommonTitlebarComponent implements OnInit {

  systemName = 'Livonia Technology Solutions Private Limited';
  customerName = 'Example Customer';
  departmentName ='Example Department';
  constructor() { }

  ngOnInit() {
  }
  logOutUser(){
    this.systemName = " ";
    this.customerName = " ";
    this.departmentName = " ";
  }

}
