import { Component, OnInit } from '@angular/core';

import { ProfileService } from '../../profile/profile.service';
@Component({
  selector: 'app-common-breadcrumbs',
  templateUrl: './common-breadcrumbs.component.html',
  styleUrls: ['./common-breadcrumbs.component.css']
})
export class CommonBreadcrumbsComponent implements OnInit {

  constructor(private profileService: ProfileService) { }

  ngOnInit() {
  }

}
