import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({
  })
};


@Injectable()
export class HttpConfigService{
   constructor(private http: HttpClient) { }

   //baseURL="http://localhost:3000/nodejsapp/koshys/";
   baseURL="https://liveclass.pro910.com/nodejsapp/koshys/";


   //baseERPURL="http://localhost/KGI/";
   baseERPURL="https://kgi.pro910.com/Pro910/";


}
