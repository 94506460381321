import { Component, OnInit } from '@angular/core';

import { PageHeadingService } from '../../services/page-heading.service';
import { ProfileService } from '../../profile/profile.service';
@Component({
  selector: 'app-common-pagetitle',
  templateUrl: './common-pagetitle.component.html',
  styleUrls: ['./common-pagetitle.component.css']
})
export class CommonPagetitleComponent implements OnInit {
  sampleTitle = "Sample Page";
  constructor(
    private profileService: ProfileService,
    private pageHeadingService: PageHeadingService
  ) { }

  ngOnInit() {

  }

}
