import { Component, OnInit } from '@angular/core';
import { FormGroup,FormControl,Validators } from '@angular/forms';

import { GenericDetailsService } from '../../services/generic-details.service';
import { ProfileService } from '../../profile/profile.service';
import { EventService } from '../event.service';

@Component({
  selector: 'app-teacher-new-class',
  templateUrl: './teacher-new-class.component.html',
  styleUrls: ['./teacher-new-class.component.css']
})
export class TeacherNewClassComponent implements OnInit {

  eventTitle;
  eventStart;
  eventEnd;
  cources;
  departments;
  eventDate = new Date();
  //eventPropDate = eventDate | date:'yyyy-MM-dd';
  deptName=0;
  courceName =0;
  semName= 0;
  btnIsDisabled;
  dispSuccess;
  event_form;

  eventForn: FormGroup;
  constructor(
    private genericDetailsService: GenericDetailsService,
    private profileService: ProfileService,
    private eventService: EventService,
  ) { }

  ngOnInit() {

    this.eventForn = new FormGroup({
      'eventTitle': new FormControl(null,Validators.required),
      'eventDate': new FormControl(this.eventDate,Validators.required),
      'eventStart': new FormControl(null,Validators.required),
      'eventEnd': new FormControl(null,Validators.required),
      'selecteedDept': new FormControl(0,Validators.min(1)),
      'selecteedCource': new FormControl(0,Validators.min(1)),
      'selecteedSem': new FormControl(0,Validators.min(1)),
      'eventOrganizer': new FormControl('null')
    });

    this.genericDetailsService.getAllDepartments()
      .subscribe((res) => {
        this.departments = res;
        //console.log(res);
      });

    this.genericDetailsService.getAllCources()
      .subscribe((res) => {
        this.cources = res;
        //console.log(res);
      });
  }
  changeDept(deptId){
    this.genericDetailsService.getDeptCources(deptId)
      .subscribe((res) => {
        this.cources = res;
        //console.log(res);
      });
  }

  saveEvent(){
    this.btnIsDisabled = true;
    this.dispSuccess  =false;
    //console.log(this.eventForn.value);
    this.eventForn.value.eventOrganizer=this.profileService.curLoggedUserMail;
    //console.log(this.profileService.curLoggedUserMail);
    this.eventService.addNewEvent(this.eventForn.value)
      .subscribe((res) => {
        //console.log(res);
          this.dispSuccess = true;
          this.btnIsDisabled = false;
      });

  }

}
