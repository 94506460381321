import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpConfigService } from './services/http-config.service';
import { PageHeadingService } from './services/page-heading.service';
import { GenericDetailsService } from './services/generic-details.service';
import { EventService } from './classroom/event.service';
import { ProfileService } from './profile/profile.service';

import { CommonNavbarComponent } from './common/common-navbar/common-navbar.component';
import { CommonTitlebarComponent } from './common/common-titlebar/common-titlebar.component';
import { CommonFooterComponent } from './common/common-footer/common-footer.component';
import { CommonBlankspaceComponent } from './common/common-blankspace/common-blankspace.component';
import { CommonBreadcrumbsComponent } from './common/common-breadcrumbs/common-breadcrumbs.component';
import { CommonPagetitleComponent } from './common/common-pagetitle/common-pagetitle.component';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';
import { CommonDashboardComponent } from './common/common-dashboard/common-dashboard.component';

import { TeacherDashboardComponent } from './classroom/teacher-dashboard/teacher-dashboard.component';
import { TeacherMyClassesComponent } from './classroom/teacher-my-classes/teacher-my-classes.component';
import { TeacherNewClassComponent } from './classroom/teacher-new-class/teacher-new-class.component';
import { StudentDashboardComponent } from './classroom/student-dashboard/student-dashboard.component';
import { StudentMyClassesComponent } from './classroom/student-my-classes/student-my-classes.component';

import { AuthUserComponent } from './profile/auth-user/auth-user.component';
import { LoadingComponent } from './common/loading/loading.component';



const appRoutes: Routes = [
  { path: '', component: CommonDashboardComponent },
  { path: 'auth/:userSecret', component: AuthUserComponent },
  { path: 'live-session', component: TeacherDashboardComponent },
  { path: 'live-session/my', component: TeacherMyClassesComponent },
  { path: 'live-session/all', component: TeacherMyClassesComponent },
  { path: 'live-class', component: StudentDashboardComponent },
  { path: 'page-loading', component: LoadingComponent },
  { path: 'not-found', component: PageNotFoundComponent },
  { path: '**', redirectTo: '/not-found' },
];

@NgModule({
  declarations: [
    AppComponent,
    CommonNavbarComponent,
    CommonTitlebarComponent,
    CommonFooterComponent,
    CommonBlankspaceComponent,
    CommonBreadcrumbsComponent,
    CommonPagetitleComponent,
    TeacherDashboardComponent,
    TeacherMyClassesComponent,
    TeacherNewClassComponent,
    StudentDashboardComponent,
    StudentMyClassesComponent,
    PageNotFoundComponent,
    CommonDashboardComponent,
    AuthUserComponent,
    LoadingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes)
  ],
  providers: [HttpConfigService,ProfileService,EventService,PageHeadingService,GenericDetailsService],
  bootstrap: [AppComponent]
})
export class AppModule { }
