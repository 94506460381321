//Author :Jibin Sebastian
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { HttpConfigService } from './http-config.service';

import { ProfileService } from '../profile/profile.service';
const httpOptions = {
  headers: new HttpHeaders({
  })
};


@Injectable()
export class GenericDetailsService{
   constructor(
     private http: HttpClient,
     private httpConfigService: HttpConfigService,
     private profileService: ProfileService
   ) { }

   baseURL = this.httpConfigService.baseURL;


   allCourceListAPI = this.baseURL + 'generic/cources/';
   selDeptCourceListAPI = this.baseURL + 'generic/cources-dept/';
   allDeptListAPI = this.baseURL + 'generic/departments/';
   oneCourceNameAPI = this.baseURL + 'generic/cource/name/';

  getAllCources(){

    return this.http.get(this.allCourceListAPI);

  }
  getDeptCources(deptId){
    return this.http.get(this.selDeptCourceListAPI+deptId);
  }

  getAllDepartments(){

    return this.http.get(this.allDeptListAPI);
  }
  getCourceName(courseId){
    return this.http.get(this.oneCourceNameAPI+courseId);
  }



}
