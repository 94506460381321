import { Injectable } from '@angular/core';
@Injectable()
export class PageHeadingService{
   constructor() { }
   pageHeading: string;

   setPageHeading(heading: string){
     this.pageHeading = heading;
   }

   getPageHeading(){
     return this.pageHeading;
   }
}
