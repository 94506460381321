import { Component, OnInit  } from '@angular/core';
import { ProfileService } from '../../profile/profile.service';
import { PageHeadingService } from '../../services/page-heading.service';
import { EventService } from '../event.service';
import { GenericDetailsService } from '../../services/generic-details.service';

@Component({
  selector: 'app-teacher-my-classes',
  templateUrl: './teacher-my-classes.component.html',
  styleUrls: ['./teacher-my-classes.component.css']
})
export class TeacherMyClassesComponent implements OnInit {
  events;
  evnttimer;
  meetLink="https://meet.google.com/";
  allCources;
  invDone;
  inviteInProg;
  inviteSuccess;
  //meetLink="https://www.google.com/search?q=";
  constructor(
    private profileService: ProfileService,
    private eventService: EventService,
    private pageHeadingService: PageHeadingService,
    private genericDetailsService: GenericDetailsService
  ) { }

  ngOnInit() {

    this.pageHeadingService.setPageHeading("My Class");
    //console.log(this.profileService.curLoggedUserId);
    this.eventService.getMyEvents(this.profileService.curLoggedUserId)
      .subscribe((res) => {
        this.events = res;
        //console.log(res);
      });

      this.genericDetailsService.getAllCources()
        .subscribe((res) => {
          this.allCources = res;
          //console.log(res);
        });

    setInterval(()=>{
      //console.log(this.profileService.curLoggedUserId);
      this.eventService.getMyEvents(this.profileService.curLoggedUserId)
        .subscribe((res) => {
          this.events = res;
          //console.log(res);
        });
    }, 5000);
  }

  goToUrl(extUrl){
    window.open(extUrl, "_blank");
  }
  getCourceNamenSem(classId,semId){
    for (let oneCource of this.allCources) {
      if(oneCource.Courses_id===classId){
        //console.log(oneCource); // 1, "string", false
        return oneCource.short_form+' '+'Sem: '+semId;
      }
    }
  }
  inviteAll(eventId){
    this.inviteInProg=true;
    this.eventService.inviteAll(eventId)
      .subscribe((res) => {
        //console.log(res);
        for (let oneEvent of this.events) {
          if(oneEvent.live_class_id===eventId){
            this.inviteInProg=false;
            this.inviteSuccess=true;
             // 1, "string", false;
            //console.log(oneEvent);
          }
        }
      });
  }
}
